var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"pa-2 elevation-4 rounded-t",attrs:{"dark":"","color":"primary"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":[
                            {
                                text: 'Name', 
                                value: '__name',
                            },
                            {
                                text: 'Email', 
                                value: 'email',
                            } ],"item-value":function (item) { return item; },"light":"","flat":"","solo":"","hide-details":""},model:{value:(_vm.search.field),callback:function ($$v) {_vm.$set(_vm.search, "field", $$v)},expression:"search.field"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-text-field',{attrs:{"flat":"","solo-inverted":"","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify","type":"search","label":("Search Courses by " + (_vm.search.field.text))},model:{value:(_vm.search.term),callback:function ($$v) {_vm.$set(_vm.search, "term", $$v)},expression:"search.term"}}),_c('v-spacer')],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.companies,"loading":_vm.isLoadingCompanies,"server-items-length":_vm.pagination.itemsTotalCount,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc,"must-sort":"","hide-default-footer":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
                        var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"28"}},[_c('v-img',{attrs:{"src":item.logo.src}})],1)]}},{key:"item.plan",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan.name)+" ")]}},{key:"item.createdAt",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt, 'verbose'))+" ")]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.showViewedCompany(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-unfold-more-horizontal")])],1)]}}],null,true)},[_c('span',[_vm._v("Expand Details")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.blockedAt ? 'green' : 'red',"loading":_vm.isTogglingCompaniesBlock[item.id]},on:{"click":function($event){return _vm.toggleCompanyBlock(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.blockedAt ? 'Unblock' : 'Block')+" Company")])])]}},{key:"top",fn:function(){return [_c('div',{staticClass:"table-top px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}}),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","icon":"","small":"","loading":_vm.isReloadingCompanies},on:{"click":function($event){return _vm.reloadCompanies()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reload")])]),_c('div',{staticClass:"caption text-right"},[_vm._v(" showing "+_vm._s(_vm.companies.length)+" of "+_vm._s(_vm.pagination.itemsTotalCount)+" results ")])],1)],1)],1)]},proxy:true},((_vm.pagination.itemsTotalCount > _vm.companies.length) && !_vm.isLoadingCompanies)?{key:"footer",fn:function(){return [_c('div',{staticClass:"table-bottom px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.loadMoreCompanies()}}},[_vm._v(" Load More ... ")])],1)])],1)],1)]},proxy:true}:null],null,true)}),(_vm.viewedCompany)?_c('company-details-dialog',{attrs:{"isActive":_vm.isShowingViewedCompany,"company":_vm.viewedCompany},on:{"close":function($event){return _vm.hideViewedCompany()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }